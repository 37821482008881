import { PrivyClientConfig } from '@privy-io/react-auth';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import { createConfig, http } from 'wagmi';
import { Chain, mainnet, sepolia } from 'wagmi/chains';
import { coinbaseWallet, injected } from 'wagmi/connectors';

const GethChain: Chain = {
    id: 100,
    name: 'Geth Chain',
    nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
    },
    rpcUrls: {
        default: {
            http: [`${document.location.protocol}//${document.location.host}/ethereum`],
        },
    },
    testnet: false,
};

const HardhatChain: Chain = {
    id: 1337,
    name: 'Hardhat Chain',
    nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
    },
    rpcUrls: {
        default: {
            http: [
                window.Cypress
                    ? 'http://ethereum:8545'
                    : `${document.location.protocol}//${document.location.host}/ethereum`,
            ],
        },
    },
    testnet: false,
};

export const config = createConfig({
    chains: [mainnet, sepolia, GethChain, HardhatChain],
    connectors: [coinbaseWallet(), injected()],
    transports: {
        [mainnet.id]: http(),
        [sepolia.id]: http(),
        [GethChain.id]: http(),
        [HardhatChain.id]: http(),
    },
});

export function getLoginMethods(): PrivyClientConfig['loginMethods'] {
    if (initialRuntimeConfig.PRIVY_EMAIL_AND_SMS_ENABLED === 'true') {
        return ['wallet', 'email', 'sms'];
    }
    return ['wallet'];
}
