import { EpochConfig, OperatorConfig, RuntimeConfig } from '@derivadex/types';
import { AppState } from 'store/slices';

export function getRuntimeConfig(state: AppState): RuntimeConfig {
    return state.config.runtime;
}

export function isServerLoadComplete(state: AppState): boolean {
    return state.config.isServerLoadComplete;
}

export function getOperatorConfig(state: AppState): OperatorConfig | undefined {
    return state.config.operator;
}

export function getEpochConfig(state: AppState): EpochConfig | undefined {
    return state.config.epoch;
}

export const getExpectedChainId = (state: AppState) => parseInt(state.config.runtime.CHAIN_ID || '-1');

export const getPortfolioPageEnabled = (state: AppState) =>
    state.config.runtime.PORTFOLIO_PAGE_ENABLED === 'true'
        ? true
        : state.config.runtime.PORTFOLIO_PAGE_ENABLED === 'false'
        ? false
        : false;

export const getStatsApiUrl = (state: AppState) =>
    state.config.runtime.STATS_API_URL != undefined && state.config.runtime.STATS_API_URL != ''
        ? state.config.runtime.STATS_API_URL
        : `${document.location.protocol}//${document.location.host}/stats/api/v1`;
