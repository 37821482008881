import { ConfigState, EpochConfig, OperatorConfig, RuntimeConfig } from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialRuntimeConfig } from '../../config/runtimeConfig';

export const initialConfigState: ConfigState = {
    isServerLoadComplete: false,
    runtime: initialRuntimeConfig,
    operator: undefined,
    epoch: undefined,
};

export const configSlice = createSlice({
    name: 'config',
    reducers: {
        UPDATE_RUNTIME_CONFIG: (
            state,
            action: PayloadAction<{
                runtimeConfig: Partial<RuntimeConfig>;
                isServerLoadComplete: boolean;
            }>,
        ) => {
            state.isServerLoadComplete = action.payload.isServerLoadComplete;
            state.runtime = {
                ...state.runtime,
                ...action.payload.runtimeConfig,
            };
        },
        UPDATE_OPERATOR_CONFIG: (state, action: PayloadAction<OperatorConfig>) => {
            state.operator = {
                ...action.payload,
            };
        },
        UPDATE_EPOCH_CONFIG: (state, action: PayloadAction<EpochConfig>) => {
            state.epoch = {
                ...action.payload,
            };
        },
    },
    initialState: initialConfigState,
});

export const configSlices = configSlice.actions;
