import { BigNumber } from '@0x/utils';
import {
    ToastUIState,
    UIDowntimeCode,
    UIDowntimeState,
    UIDowntimeSubcode,
    UIToast,
    UIToastActionID,
} from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum CancelUIState {
    NONE,
    PENDING_CONFIRMATION,
    IN_PROGRESS,
}

export enum SubmitCheckpointUIState {
    NONE,
    PENDING_CONFIRMATION,
}

export enum ClosePositionUIState {
    NONE,
    PENDING_USER_CONFIRMATION,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    WALLET_REJECTED,
    TRANSACTION_EXECUTION_FAILED,
    TRANSACTION_VALIDATION_FAILED,
    TRANSACTION_CONFIRMED,
}

export enum ToggleUsdcUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum ToggleDdxUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum ProfileIntentUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum OneClickTradingIntentUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum DepositUsdcUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    CONFIRMED,
}

export enum WithdrawIntentUsdcUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    IN_PROGRESS,
}

export enum WithdrawUsdcUIState {
    NONE,
    PENDING_CONFIRMATION,
}

export enum DepositDdxUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    CONFIRMED,
}

export enum WithdrawIntentDdxUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    IN_PROGRESS,
}

export enum WithdrawDdxUIState {
    NONE,
    PENDING_CONFIRMATION,
}

export enum PlaceOrderUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    WALLET_REJECTED,
    TRANSACTION_EXECUTION_FAILED,
    TRANSACTION_VALIDATION_FAILED,
    TRANSACTION_CONFIRMED,
}

export enum UIViewState {
    MARKETPLACE,
    PORTFOLIO,
}

export type UIState = {
    isNode0Healthy: boolean;
    isDepositDialogActive: boolean;
    isWithdrawTokensDialogActive: boolean;
    isWithdrawDdxDialogActive: boolean;
    isWalletDialogActive: boolean;
    isConsentDialogActive: boolean;
    isMarketsDialogActive: boolean;
    isStrategiesDialogActive: boolean;
    cancel: CancelUIState;
    checkpoint: SubmitCheckpointUIState;
    closePosition: ClosePositionUIState;
    depositUsdc: DepositUsdcUIState;
    depositDdx: DepositDdxUIState;
    withdrawIntentUsdc: WithdrawIntentUsdcUIState;
    withdrawUsdc: WithdrawUsdcUIState;
    withdrawIntentDdx: WithdrawIntentDdxUIState;
    withdrawDdx: WithdrawDdxUIState;
    toggleUsdc: ToggleUsdcUIState;
    toggleDdx: ToggleDdxUIState;
    profileIntent: ProfileIntentUIState;
    oneClickTradingIntent: OneClickTradingIntentUIState;
    placeOrder: PlaceOrderUIState;
    toasts: ToastUIState;
    orderbookPriceSelected: BigNumber | undefined;
    downtime: boolean;
    downtimeMessage: UIDowntimeState;
    downtimeCode: UIDowntimeCode;
    downtimeSubcode: UIDowntimeSubcode | undefined;
    view: UIViewState;
};

export const initialUIState: UIState = {
    isNode0Healthy: true,
    isDepositDialogActive: false,
    isWithdrawTokensDialogActive: false,
    isWithdrawDdxDialogActive: false,
    isWalletDialogActive: false,
    isConsentDialogActive: false,
    isMarketsDialogActive: false,
    isStrategiesDialogActive: false,
    cancel: CancelUIState.NONE,
    checkpoint: SubmitCheckpointUIState.NONE,
    closePosition: ClosePositionUIState.NONE,
    depositUsdc: DepositUsdcUIState.NONE,
    depositDdx: DepositDdxUIState.NONE,
    withdrawIntentUsdc: WithdrawIntentUsdcUIState.NONE,
    withdrawUsdc: WithdrawUsdcUIState.NONE,
    withdrawIntentDdx: WithdrawIntentDdxUIState.NONE,
    withdrawDdx: WithdrawDdxUIState.NONE,
    toggleUsdc: ToggleUsdcUIState.NONE,
    toggleDdx: ToggleDdxUIState.NONE,
    profileIntent: ProfileIntentUIState.NONE,
    oneClickTradingIntent: OneClickTradingIntentUIState.NONE,
    placeOrder: PlaceOrderUIState.NONE,
    toasts: { activeToast: undefined, toastQueue: [], pendingToasts: {} },
    orderbookPriceSelected: undefined,
    downtime: false,
    downtimeMessage: UIDowntimeState.NONE,
    downtimeCode: UIDowntimeCode.NONE,
    downtimeSubcode: undefined,
    view: UIViewState.MARKETPLACE,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialUIState,
    reducers: {
        TOGGLE_DEPOSIT_COLLATERALS_DIALOG: (state) => {
            state.isDepositDialogActive = !state.isDepositDialogActive;
        },
        TOGGLE_WITHDRAW_TOKENS_DIALOG: (state) => {
            state.isWithdrawTokensDialogActive = !state.isWithdrawTokensDialogActive;
        },
        TOGGLE_WITHDRAW_DDX_DIALOG: (state) => {
            state.isWithdrawDdxDialogActive = !state.isWithdrawDdxDialogActive;
        },
        TOGGLE_WALLET_DIALOG: (state) => {
            state.isWalletDialogActive = !state.isWalletDialogActive;
        },
        TOGGLE_CONSENT_DIALOG: (state) => {
            state.isConsentDialogActive = !state.isConsentDialogActive;
        },
        TOGGLE_MARKETS_DIALOG: (state) => {
            state.isMarketsDialogActive = !state.isMarketsDialogActive;
        },
        TOGGLE_STRATEGIES_DIALOG: (state) => {
            state.isStrategiesDialogActive = !state.isStrategiesDialogActive;
        },
        SET_CANCEL_UI_STATE: (state, action: PayloadAction<CancelUIState>) => {
            state.cancel = action.payload;
        },
        SET_SUBMIT_CHECKPOINT_UI_STATE: (state, action: PayloadAction<SubmitCheckpointUIState>) => {
            state.checkpoint = action.payload;
        },
        SET_CLOSE_POSITION_UI_STATE: (state, action: PayloadAction<ClosePositionUIState>) => {
            state.closePosition = action.payload;
        },
        SET_DEPOSIT_USDC_UI_STATE: (state, action: PayloadAction<DepositUsdcUIState>) => {
            state.depositUsdc = action.payload;
        },
        SET_DEPOSIT_DDX_UI_STATE: (state, action: PayloadAction<DepositDdxUIState>) => {
            state.depositDdx = action.payload;
        },
        SET_WITHDRAW_INTENT_USDC_UI_STATE: (state, action: PayloadAction<WithdrawIntentUsdcUIState>) => {
            state.withdrawIntentUsdc = action.payload;
        },
        SET_WITHDRAW_USDC_UI_STATE: (state, action: PayloadAction<WithdrawUsdcUIState>) => {
            state.withdrawUsdc = action.payload;
        },
        SET_WITHDRAW_INTENT_DDX_UI_STATE: (state, action: PayloadAction<WithdrawIntentDdxUIState>) => {
            state.withdrawIntentDdx = action.payload;
        },
        SET_WITHDRAW_DDX_UI_STATE: (state, action: PayloadAction<WithdrawDdxUIState>) => {
            state.withdrawDdx = action.payload;
        },
        SET_TOGGLE_USDC_UI_STATE: (state, action: PayloadAction<ToggleUsdcUIState>) => {
            state.toggleUsdc = action.payload;
        },
        SET_TOGGLE_DDX_UI_STATE: (state, action: PayloadAction<ToggleDdxUIState>) => {
            state.toggleDdx = action.payload;
        },
        SET_PROFILE_INTENT_UI_STATE: (state, action: PayloadAction<ProfileIntentUIState>) => {
            state.profileIntent = action.payload;
        },
        SET_ONE_CLICK_TRADING_INTENT_UI_STATE: (state, action: PayloadAction<OneClickTradingIntentUIState>) => {
            state.oneClickTradingIntent = action.payload;
        },
        SET_PLACE_ORDER_UI_STATE: (state, action: PayloadAction<PlaceOrderUIState>) => {
            state.placeOrder = action.payload;
        },
        ADD_TOAST_MESSAGE: (state, action: PayloadAction<UIToast>) => {
            state.toasts.toastQueue = state.toasts.toastQueue.concat(action.payload);
        },
        ADD_PENDING_TOAST: (state, action: PayloadAction<{ actionID: UIToastActionID; id: string }>) => {
            const shallow: { [actionID: string]: string } = { ...state.toasts.pendingToasts };
            shallow[action.payload.actionID] = action.payload.id;
            state.toasts.pendingToasts = shallow;
        },
        REMOVE_PENDING_TOAST: (
            state,
            action: PayloadAction<{
                actionID: UIToastActionID;
            }>,
        ) => {
            const actionID = action.payload.actionID;
            const { [actionID]: value, ...rest } = state.toasts.pendingToasts;
            state.toasts.pendingToasts = rest;
        },
        PROCESS_TOAST_MESSAGE: (state) => {
            [state.toasts.activeToast, ...state.toasts.toastQueue] = state.toasts.toastQueue;
        },
        CLEAR_ACTIVE_TOAST_MESSAGE: (state) => {
            state.toasts.activeToast = undefined;
        },
        SET_SELECTED_ORDERBOOK_PRICE: (state, action: PayloadAction<BigNumber>) => {
            state.orderbookPriceSelected = action.payload;
        },
        SET_DOWNTIME: (
            state,
            action: PayloadAction<{ message: UIDowntimeState; code: UIDowntimeCode; subcode?: UIDowntimeSubcode }>,
        ) => {
            state.downtime = true;
            state.downtimeMessage = action.payload.message;
            state.downtimeCode = action.payload.code;
            state.downtimeSubcode = action.payload.subcode;
        },
        TOGGLE_CURRENT_VIEW: (
            state,
            action: PayloadAction<{
                view: UIViewState;
            }>,
        ) => {
            state.view = action.payload.view;
        },
        SET_IS_NODE0_HEALTHY: (state, action: PayloadAction<boolean>) => {
            state.isNode0Healthy = action.payload;
        },
    },
});

export default uiSlice.reducer;

export const {
    TOGGLE_DEPOSIT_COLLATERALS_DIALOG,
    TOGGLE_WITHDRAW_TOKENS_DIALOG,
    TOGGLE_WITHDRAW_DDX_DIALOG,
    TOGGLE_WALLET_DIALOG,
    TOGGLE_CONSENT_DIALOG,
    TOGGLE_MARKETS_DIALOG,
    TOGGLE_STRATEGIES_DIALOG,
    SET_CANCEL_UI_STATE,
    SET_SUBMIT_CHECKPOINT_UI_STATE,
    SET_CLOSE_POSITION_UI_STATE,
    SET_DEPOSIT_USDC_UI_STATE,
    SET_DEPOSIT_DDX_UI_STATE,
    SET_WITHDRAW_INTENT_USDC_UI_STATE,
    SET_WITHDRAW_USDC_UI_STATE,
    SET_WITHDRAW_INTENT_DDX_UI_STATE,
    SET_WITHDRAW_DDX_UI_STATE,
    SET_TOGGLE_USDC_UI_STATE,
    SET_TOGGLE_DDX_UI_STATE,
    SET_PROFILE_INTENT_UI_STATE,
    SET_ONE_CLICK_TRADING_INTENT_UI_STATE,
    SET_PLACE_ORDER_UI_STATE,
    ADD_TOAST_MESSAGE,
    ADD_PENDING_TOAST,
    REMOVE_PENDING_TOAST,
    PROCESS_TOAST_MESSAGE,
    CLEAR_ACTIVE_TOAST_MESSAGE,
    SET_SELECTED_ORDERBOOK_PRICE,
    SET_DOWNTIME,
    TOGGLE_CURRENT_VIEW,
    SET_IS_NODE0_HEALTHY,
} = uiSlice.actions;
