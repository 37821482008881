import * as wasmModule from '@derivadex/ddx-wasm';
import { getErrorMessage } from '@derivadex/utils';
import { Buffer } from 'buffer/';
import { randomBytes } from 'crypto';
import { utils } from 'ethers';

/***
 * JSON encode then encrypt
 */
export const encryptIntent = (encryptionKey: string, payload: any) => {
    try {
        // Create an ephemeral ECDSA private key to encrypt the request.
        const secretKeyBytes = new Uint8Array(randomBytes(32));

        // Unique single-use nonce for each encryption.
        // It is important to never repeat nonces.
        const nonceBytes = new Uint8Array(randomBytes(12));

        const json = JSON.stringify(payload);
        const buffer = Buffer.from(json);
        // We use native Uint8Array where possible to avoid unnecessary string operations.
        const requestBytes = new Uint8Array(buffer);

        const encryptedBytes = wasmModule.encrypt(requestBytes, secretKeyBytes, encryptionKey, nonceBytes);
        const hex = utils.hexlify(encryptedBytes);

        return hex;
    } catch (error: any) {
        throw new Error(`System malfunction: Could not encrypt intent. ${getErrorMessage(error)}.`);
    }
};
